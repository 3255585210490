<template>
  <el-dialog
    visible
    append-to-body
    top="33vh"
    :show-close="false"
    custom-class="rounded add-membership-modal"
    width="400px"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2>{{ $t('userManagement.addEditMembershipModal.title') }}</h2>
        <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="py-4 px-5">
      <el-form ref="form" :model="model" :show-message="false">
        <el-form-item prop="businessId" required class="mb-1">
          <el-select
            v-model="model.businessId"
            filterable
            size="mini"
            style="width: 100%"
            :placeholder="$t('userManagement.addEditMembershipModal.business')"
          >
            <el-option v-for="tenant in tenants" :key="tenant.id" :label="tenant.name" :value="tenant.id" />
          </el-select>
        </el-form-item>
        <el-form-item prop="role" required class="mb-1">
          <MembershipRoleDropdown
            :value="model.role"
            :allowed-roles="allowedRoles"
            :business-id-to-invite-to="model.businessId"
            :business-type="businessType"
            :mini="true"
            :disabled="!chosenTenant"
            @on-role-change="(newValue) => (model.role = newValue)"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="$emit('close')">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleAddButtonClick">
          {{ $t('userManagement.addEditMembershipModal.add') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed, reactive } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { MembershipRoleDropdown } from '@/modules/permissions/components';

import { ROLE_TYPE, BUSINESS_TYPE } from '../constants';

export default {
  components: { Button, CloseIcon, MembershipRoleDropdown },
  props: {
    tenants: { type: Array, default: () => [] },
  },
  emits: ['create', 'close'],
  setup(props, { emit }) {
    const form = ref(null);
    const model = reactive({
      businessId: null,
      role: null,
    });

    const chosenTenant = computed(() => props.tenants.find(({ id }) => id === model.businessId));

    const allowedRoles = computed(() => {
      const business = props.tenants.find((t) => t.id === chosenTenant.value?.id);
      if (business) {
        if (business.type === BUSINESS_TYPE.SERVICE_PROVIDER) return [ROLE_TYPE.ADMIN];
        if (business.type === BUSINESS_TYPE.RESTAURANT)
          return [
            ROLE_TYPE.ORDERER,
            ROLE_TYPE.PURCHASE_MANAGER,
            ROLE_TYPE.MANAGER,
            ROLE_TYPE.PAYMENTS_MANAGER,
            ROLE_TYPE.OWNER,
          ];
      }

      return [];
    });

    const businessType = computed(() => props.tenants.find((t) => t.id === chosenTenant.value?.id)?.type);

    const handleAddButtonClick = () => {
      form.value.validate(async (valid) => {
        if (valid) {
          emit('create', model);
          emit('close');
        }
      });
    };

    return {
      ROLE_TYPE,
      BUSINESS_TYPE,
      form,
      model,
      chosenTenant,
      allowedRoles,
      businessType,
      handleAddButtonClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep .add-membership-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}
</style>
