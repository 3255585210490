<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('userManagement.title') }}</h1>
    </template>
    <div @mousewheel="closeActions">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h3>{{ $t('userManagement.table.title') }}</h3>
        <Button @click="showEnrollmentModal = true"> {{ $t('userManagement.enrollment') }} </Button>
      </div>

      <SearchBar
        :search-value="usersSearch"
        :placeholder="$t('userManagement.searchbarPlaceholder')"
        @onClear="() => (usersSearch = '')"
        @onChange="onSearchChange"
      />

      <Table
        v-loading="!isLoadingMore && loading"
        :data="tableData"
        :columns="tableColumns"
        expandable
        row-click-expand-toggle
        show-index
        rounded
        border
        class="mb-4"
        custom-class="user-table"
      >
        <template
          #cell-fullName="{
            rowData: {
              fullName: { firstName, lastName, picture },
            },
          }"
        >
          <div class="d-flex gap-2 align-items-center">
            <img v-if="picture" :src="`${picture}&token=${token}`" width="24" height="24" />
            <div
              v-else
              class="bg-primary rounded-pill d-flex justify-content-center align-items-center"
              style="height: 24px; width: 24px"
            >
              <small class="text-uppercase text-white fs-bold">{{ firstName[0] + lastName[0] }}</small>
            </div>

            <small>{{ `${firstName} ${lastName}` }}</small>
          </div>
        </template>
        <template #cell-category="{ rowData: { category } }">
          <SupplierIcon v-if="category === BUSINESS_TYPE.SUPPLIER" width="16px" height="16px" />
          <ShopIcon v-else-if="category === BUSINESS_TYPE.RESTAURANT" width="16px" height="16px" />
          <ClarityIcon v-else-if="category === BUSINESS_TYPE.SERVICE_PROVIDER" width="16px" height="16px" />
          <p v-else>-</p>
        </template>
        <template #cell-status="{ rowData: { verified } }">
          <div class="d-flex flex-row gap-2">
            <div class="position-relative ms-1">
              <span
                class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center"
                :class="{
                  ['bg-success']: verified,
                  ['bg-disabled']: !verified,
                }"
              />
            </div>
            <p>
              {{ verified ? $t('userManagement.table.status.active') : $t('userManagement.table.status.pending') }}
            </p>
          </div>
        </template>
        <template #expandable-content="{ rowData: { businessWithRoles } }">
          <Table
            :data="businessWithRoles"
            :columns="tableColumns"
            :hover="false"
            custom-class="bg-light inner-table"
            show-index
            expandable
          >
            <template #cell-index>
              <p no-index />
            </template>
            <template #cell-category="{ rowData: { category } }">
              <SupplierIcon v-if="category === BUSINESS_TYPE.SUPPLIER" width="16px" height="16px" />
              <ShopIcon v-else-if="category === BUSINESS_TYPE.RESTAURANT" width="16px" height="16px" />
              <img
                v-else-if="category === BUSINESS_TYPE.SERVICE_PROVIDER"
                src="@/assets/images/sidemenu-logo.png"
                width="24"
                height="24"
              />
              <p v-else>-</p>
            </template>
          </Table>
        </template>

        <template #cell-actions="{ rowIndex, rowData: { verified } }">
          <el-dropdown
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom"
            @command="handleAction($event, rowIndex)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button
              :id="`actions-row-${rowIndex}`"
              type="text"
              class="p-1 actions-btn text-typography-primary"
              :class="{ active: activeActions === rowIndex }"
              @click.stop
            >
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :command="ACTIONS.RESEND" :disabled="verified">
                <div class="d-flex align-items-center gap-2">
                  <EmailIcon />
                  <p>{{ $t('userManagement.resendEnrollment') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item :command="ACTIONS.EDIT_USER">
                <div class="d-flex align-items-center gap-2">
                  <EditIcon />
                  <p>{{ $t('userManagement.editUser') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </Table>
      <Button
        v-if="usersPageInfo.hasNextPage"
        v-loading="isLoadingMore"
        type="secondary"
        class="w-100 actions-btn text-typography-primary"
        @click="loadMoreUsers()"
      >
        {{ $t('userManagement.loadMore') }}
      </Button>
    </div>
    <EditUserModal
      v-if="editUserModalOpen"
      :tenants="tenantsClarityWorksWith"
      :user="userToEdit"
      @close="onCloseEditModal"
    />
    <EnrollmentModal v-if="showEnrollmentModal" :tenants="tenantsClarityWorksWith" @close="onCloseEnrollmentModal" />
  </PageLayout>
</template>

<script type="text/javascript">
import { uniq } from 'ramda';
import { ref, computed, getCurrentInstance, watch } from 'vue';
import { uniqBy } from 'lodash';
import { SupplierIcon, ShopIcon, ClarityIcon, KebabIcon, EditIcon, EmailIcon } from '@/assets/icons';
import { useUser } from '@/modules/auth';
import { PageLayout, Table, Button, SearchBar } from '@/modules/core';
import { useAllTenants } from '../core/compositions/all-tenants';
import { useUsers, useUserEnroll, useBusinessesClarityWorkWith } from './compositions';
import { EnrollmentModal, EditUserModal } from './components';
import { BUSINESS_TYPE, ROLE_TYPE } from './constants';

const TABLE_HEADER = {
  FULL_NAME: 'fullName',
  BUSINESS: 'business',
  CATEGORY: 'category',
  ROLE: 'role',
  EMAIL: 'email',
  PHONE: 'phone',
  CREATED_AT: 'createdAt',
  STATUS: 'status',
};

const ACTIONS = {
  RESEND: 'resend',
  EDIT_USER: 'editUser',
};

export default {
  components: {
    PageLayout,
    Table,
    SupplierIcon,
    ShopIcon,
    ClarityIcon,
    KebabIcon,
    EditIcon,
    EmailIcon,
    Button,
    EditUserModal,
    EnrollmentModal,
    SearchBar,
  },
  setup() {
    const root = getCurrentInstance().proxy;

    const usersSearch = ref('');
    const activeActions = ref(null);
    const editUserIndex = ref(null);
    const showEnrollmentModal = ref(false);

    const { tenants, loading: tenantsLoading } = useAllTenants();
    const { businessesClarityWorksWith, loading: businessesClarityWorksWithLoading } = useBusinessesClarityWorkWith();
    const { token, loading: userLoading } = useUser();
    const { users, loading: usersLoading, pageInfo, __typename: userTypeName, refetch } = useUsers(usersSearch);
    const { enrollUser } = useUserEnroll();
    const allUsers = ref([]);
    const isLoadingMore = ref(false);

    watch(users, (newUsers) => {
      if (!isLoadingMore.value) {
        allUsers.value = newUsers;
      }
    });

    const usersPageInfo = computed({
      get: () => pageInfo.value,
      set: (value) => value,
    });
    const loading = computed(
      () => userLoading.value || tenantsLoading.value || usersLoading.value || businessesClarityWorksWithLoading.value
    );
    const tableColumns = computed(() => [
      {
        header: root.$t('userManagement.table.headers.fullName'),
        key: TABLE_HEADER.FULL_NAME,
        width: '160px',
      },
      {
        header: root.$t('userManagement.table.headers.business'),
        key: TABLE_HEADER.BUSINESS,
        width: '140px',
      },
      {
        header: root.$t('userManagement.table.headers.category'),
        key: TABLE_HEADER.CATEGORY,
        width: '70px',
      },
      {
        header: root.$t('userManagement.table.headers.role'),
        key: TABLE_HEADER.ROLE,
        width: '110px',
      },
      {
        header: root.$t('userManagement.table.headers.email'),
        key: TABLE_HEADER.EMAIL,
        width: '180px',
      },
      {
        header: root.$t('userManagement.table.headers.phone'),
        key: TABLE_HEADER.PHONE,
        width: '120px',
      },
      {
        header: root.$t('userManagement.table.headers.createdAt'),
        key: TABLE_HEADER.CREATED_AT,
        width: '120px',
      },
      {
        header: root.$t('userManagement.table.headers.status'),
        key: TABLE_HEADER.STATUS,
        width: '140px',
      },
      {
        key: 'actions',
        width: '40px',
        customClass: 'p-0',
      },
    ]);
    const tableData = computed(() => {
      return allUsers.value.map((user) => {
        const businesses = tenants.value.filter((t) => user.memberships.some(({ businessId }) => businessId === t.id));
        const roles = uniq(user.memberships.map(({ role }) => role));

        const businessWithRoles = businesses.map((b) => {
          const relevantMembership = user.memberships.find(({ businessId }) => businessId === b.id);
          return {
            [TABLE_HEADER.BUSINESS]: b.name,
            [TABLE_HEADER.CATEGORY]: b.type,
            [TABLE_HEADER.ROLE]: getRoleTranslation(relevantMembership.role, b.type),
            membershipId: relevantMembership.id,
          };
        });

        return {
          id: user.id,
          [TABLE_HEADER.FULL_NAME]: {
            firstName: user.given_name ?? '',
            lastName: user.family_name ?? '',
            picture: user.picture,
          },
          [TABLE_HEADER.BUSINESS]: businesses.length
            ? root.$tc('userManagement.table.business', businesses.length, {
                business: businesses[0].name,
                count: businesses.length,
              })
            : '-',
          [TABLE_HEADER.EMAIL]: user.email,
          [TABLE_HEADER.ROLE]: getRolesTranslations(roles, businesses[0]?.type),
          [TABLE_HEADER.CATEGORY]: businesses[0]?.type,
          [TABLE_HEADER.PHONE]: user.phone_number ?? '-',
          [TABLE_HEADER.STATUS]: user.status,
          verified: user.status !== 'FORCE_CHANGE_PASSWORD',
          businessWithRoles,
          expandable: businesses.length > 1,
          expandableCustomClass: 'p-0 bg-light',
        };
      });
    });

    const editUserModalOpen = computed(() => editUserIndex.value !== null);
    const userToEdit = computed(() => {
      return tableData.value[editUserIndex.value];
    });
    const tenantsClarityWorksWith = computed(() =>
      tenants.value.filter((t) => businessesClarityWorksWith.value.includes(t.id))
    );

    const getRolesTranslations = (roles, businessType) => {
      if (roles.length && roles.length === 1 && businessType) {
        return getRoleTranslation(roles[0], businessType);
      }
      return roles.length
        ? root.$tc('userManagement.table.role', roles.length, {
            role: root.$t(`userManagement.roles.${roles[0].toLowerCase()}`),
            count: roles.length,
          })
        : '-';
    };

    const getRoleTranslation = (role, businessType = null) => {
      if (businessType && businessType === BUSINESS_TYPE.BOOK_KEEPING && role === ROLE_TYPE.MANAGER) {
        return root.$t('userManagement.roles.bookkeepingEmployee');
      }
      if (businessType && businessType === BUSINESS_TYPE.SUPPLIER && role === ROLE_TYPE.EMPLOYEE) {
        return root.$t('userManagement.roles.supplierEmployee');
      }
      return getRolesTranslations([role]);
    };

    const loadMoreUsers = () => {
      if (!usersPageInfo.value.hasNextPage) return;
      isLoadingMore.value = true;

      refetch({
        first: 25,
        after: usersPageInfo.value.endCursor,
      })
        .then(({ data }) => {
          if (data && data.users) {
            allUsers.value = uniqBy([...allUsers.value, ...data.users.nodes], 'id');

            usersPageInfo.value = data.users.pageInfo;
          }
        })
        .catch(() => {
          root.$message.error(root.$t('commons.messages.action.error'));
        })
        .finally(() => {
          isLoadingMore.value = false;
        });
    };

    const actionsVisibleChange = (index, isVisible) => {
      activeActions.value = isVisible ? index : null;
    };

    const closeActions = () => {
      if (activeActions.value !== null) {
        document.getElementById(`actions-row-${activeActions.value}`).click();
        activeActions.value = null;
      }
    };

    const handleAction = async (command, rowIndex) => {
      switch (command) {
        case ACTIONS.EDIT_USER:
          editUserIndex.value = rowIndex;
          break;
        case ACTIONS.RESEND: {
          const loading = root.$loading();
          try {
            await enrollUser({ userId: tableData.value[rowIndex].id });
            root.$message.success(root.$t('commons.messages.action.success'));
          } catch {
            root.$message.error(root.$t('commons.messages.action.error'));
          } finally {
            loading.close();
          }
          break;
        }
        default:
          break;
      }
    };

    const onCloseEditModal = () => {
      editUserIndex.value = null;
      refetch();
    };

    const onCloseEnrollmentModal = () => {
      showEnrollmentModal.value = false;
      refetch();
    };

    const onSearchChange = (newVal) => {
      usersSearch.value = newVal;
    };

    return {
      BUSINESS_TYPE,
      ACTIONS,
      token,
      users,
      tenants,
      loading,
      tableData,
      userToEdit,
      usersSearch,
      userLoading,
      userTypeName,
      usersLoading,
      tableColumns,
      usersPageInfo,
      activeActions,
      editUserIndex,
      tenantsLoading,
      editUserModalOpen,
      tenantsClarityWorksWith,
      showEnrollmentModal,
      enrollUser,
      closeActions,
      handleAction,
      onCloseEditModal,
      actionsVisibleChange,
      onCloseEnrollmentModal,
      loadMoreUsers,
      isLoadingMore,
      onSearchChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.user-table {
  .actions-btn {
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  .hover-btn {
    &:hover {
      background: $secondary;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

::v-deep .inner-table {
  & > thead {
    visibility: collapse;
  }
  & > tbody > tr > td {
    background: $secondary;
  }
}
</style>
